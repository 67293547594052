<template>
	<component v-bind:is="componentName" v-if="findComponent"></component>
</template>
<script>
export default {
	name: 'ListGlobal',
	data: () => ({
		findComponent: false,
		componentName: '',
	}),
	created() {
		this.setComponentName();
	},
	methods: {
		setComponentName() {
			const componentList = [
				{ componentName: 'registers-list-a', format: 'hseq-gam1-f-7' },
				{ componentName: 'registers-list-a', format: 'hseq-gam1-f-18' },
				{ componentName: 'registers-list-a', format: 'hseq-gam1-f-19' },
				{ componentName: 'registers-list-a', format: 'hseq-sso1-f-18' },
				{
					componentName: 'registers-list-hseq-sso1-f-45',
					format: 'hseq-sso1-f-45',
				},
				{
					componentName: 'registers-list-hseq-sso-f-17',
					format: 'hseq-sso-f-17',
				},
				{
					componentName: 'registers-list-hseq-sso1-f-255',
					format: 'hseq-sso1-f-255',
				},
				{
					componentName: 'registers-list-hseq-gam1-f-22',
					format: 'hseq-gam1-f-22',
				},
				{
					componentName: 'registers-list-hseq-sso1-f-5',
					format: 'hseq-sso1-f-5',
				},
				{
					componentName: 'registers-list-hseq-sso1-f-6',
					format: 'hseq-sso1-f-6',
				},
				{
					componentName: 'registers-list-hseq-sso1-f-87',
					format: 'hseq-sso1-f-87',
				},
			];
			const foundComponent = componentList.find(
				(element) => element.format === this.$route.params?.id
			);
			if (foundComponent) {
				this.componentName = foundComponent.componentName;
				this.findComponent = true;
			}
		},
	},
	components: {
		RegistersListA: () =>
			import(
				'@/components/inspections/formats/format-a/modules/list/RegistersList.vue'
			),
		RegistersListHseqSso1F45: () =>
			import(
				'@/components/inspections/formats/hseq-sso1-f-45/modules/list/RegistersList.vue'
			),
		RegistersListHseqSsoF17: () =>
			import(
				'@/components/inspections/formats/hseq-sso-f-17/modules/list/RegistersList.vue'
			),
		RegistersListHseqSso1F255: () =>
			import(
				'@/components/inspections/formats/hseq-sso1-f-255/modules/list/RegistersList.vue'
			),
		RegistersListHseqGam1F22: () =>
			import(
				'@/components/inspections/formats/hseq-gam1-f-22/modules/list/RegistersList.vue'
			),
		RegistersListHseqSso1F5: () =>
			import(
				'@/components/inspections/formats/hseq-sso1-f-5/modules/list/RegistersList.vue'
			),
		RegistersListHseqSso1F6: () =>
			import(
				'@/components/inspections/formats/hseq-sso1-f-6/modules/list/RegistersList.vue'
			),
		RegistersListHseqSso1F87: () =>
			import(
				'@/components/inspections/formats/hseq-sso1-f-87/modules/list/RegistersList.vue'
			),
	},
};
</script>
